import { useState } from 'react';
import { allMerchants, buildMerchants } from '@cortex/shared';
import { useFilterTagsContext } from '@cortex/shared/components/filter-tags/helpers/filter-tags-context';
import { BuiltFilterProps } from '@cortex/shared/components/filter-tags/helpers/types';

import { setupOnChange } from './on-change';

export function useFilter(props: BuiltFilterProps) {
  const { selectedOptions, changeHandler } = useFilterTagsContext();
  const { config } = props;

  const { componentProps = {} } = config;

  const merchant = selectedOptions.find(
    option =>
      option.config.kind === 'merchant' && option.config.name === config.name,
  );

  const merchants = buildMerchants();

  const defaultMerchantId =
    merchant?.value || componentProps.defaultMerchantId || allMerchants.id;

  const [merchantId, setMerchant] = useState<string>(
    defaultMerchantId as string,
  );

  const onChange = setupOnChange(config, changeHandler, merchants, setMerchant);

  return {
    merchants,
    merchantId,
    onChange,
  };
}
