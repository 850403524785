import { CommonFilter } from '../components/filters/common/filter';
import { DateRangeFilter } from '../components/filters/date-range/filter';
import { dateRangeDefaultValueBuilder } from '../components/filters/date-range/helpers/default-value-builder';
import { MerchantFilter } from '../components/filters/merchant/filter';
import { merchantIdDefaultBuilder } from '../components/filters/merchant/helpers/default-merchant-id';
import { BuiltFilterKinds } from './types';

export const kinds: BuiltFilterKinds = {
  'merchant': {
    defaultBuilder: merchantIdDefaultBuilder,
    component: MerchantFilter,
  },
  'date-range': {
    defaultBuilder: dateRangeDefaultValueBuilder,
    component: DateRangeFilter,
  },
  'common': {
    component: CommonFilter,
  },
};
