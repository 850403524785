import React, { LazyExoticComponent } from 'react';
import { Route, RouteObject } from 'react-router-dom';
import {
  NavCategoryType,
  NavChildrenType,
  NavRouteItem,
  ProtectedRoute,
} from '@cortex/shared';
import { useAuthorize } from '@cortex/shared/hooks/useAuthorize';
import { useFlags } from 'flagsmith/react';
import _ from 'lodash';

export interface NavItemConfig {
  path: string;
  flag?: { key: string; enabled: boolean };
  component?: LazyExoticComponent<React.ComponentType>;
  categories?: NavCategoryType[];
  children?: NavChildrenType[];
  routes?: NavRouteItem[];
}

const generateRoutesFromCategories = (
  categories: NavCategoryType[],
): RouteObject[] => {
  return categories.reduce((acc: RouteObject[], category) => {
    acc.push(...generateRoutes(category.children || []));
    return acc;
  }, []);
};

const generateRoutesFromChildren = (
  children: NavChildrenType[],
): RouteObject[] => {
  return children.reduce((acc: RouteObject[], child) => {
    if (!child.requiredPermissions || useAuthorize(child.requiredPermissions)) {
      acc.push(...generateRoutes([child]));
    }
    return acc;
  }, []);
};

const generateRoutesFromRoutesArray = (
  routes: NavRouteItem[],
): RouteObject[] => {
  return routes.map(route => ({
    path: route.path,
    element: <ProtectedRoute component={route.component} />,
  }));
};

const generateRoutes = (items: NavItemConfig[]): RouteObject[] => {
  return items.reduce((acc: RouteObject[], item) => {
    const { flag } = item;
    const { key, enabled } = flag ?? {};

    const flags = useFlags([key ?? '']);

    if (item.flag && _.get(flags, `${key}.enabled`) !== enabled) return acc;

    if (item.categories) {
      acc.push(...generateRoutesFromCategories(item.categories));
      return acc;
    }

    if (item.children) {
      acc.push(...generateRoutesFromChildren(item.children));
      return acc;
    }

    if (item.routes) {
      acc.push(...generateRoutesFromRoutesArray(item.routes));
      return acc;
    }

    if (!item.component) return acc;

    acc.push({
      path: item.path,
      element: <ProtectedRoute component={item.component} />,
    });

    return acc;
  }, []);
};

export function generatedRoutes(items: NavItemConfig[]) {
  const routes = generateRoutes(items);

  return routes.map((route, index) => (
    <Route key={index} path={route.path} element={route.element} />
  ));
}
