import React from 'react';
import Gravatar from 'react-gravatar';
import { Session } from '@cortex/shared';

export function MobileNavHeader() {
  const userData = Session.decoded;
  const userName = userData?.user?.name || 'User Name';
  const userEmail = userData?.user?.email || 'User Email';

  return (
    <div className="flex justify-center text-[#1d2547] py-2">
      <div className="flex-shrink-0">
        <Gravatar
          className="rounded-full"
          size={30}
          default="mp"
          email={userEmail}
        />
      </div>
      <div className="ml-3 text-reguard-gray">
        <div className="font-medium leading-none max-w-[120px] overflow-hidden whitespace-nowrap overflow-ellipsis">
          {userName}
        </div>
        <div className="text-sm font-medium leading-none max-w-[120px] overflow-hidden whitespace-nowrap overflow-ellipsis">
          {userEmail}
        </div>
      </div>
    </div>
  );
}
