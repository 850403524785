import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Button } from '@cortex/shared';

export const PasswordSuccessfullySet = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleLoginRedirect = () => {
    navigate('/');
  };

  return (
    <div className="bg-white sm:rounded-lg md:px-[33%] py-10 md:py-24 text-center">
      <div className="px-4 py-5 sm:p-6">
        <h4 className="tw-cst-pf md:mt-[7.625rem] mb-4 text-xl font-bold">
          {t('password_set')}
        </h4>
        <div className="flex flex-col">
          <span className="b2 mb-4 text-lg">
            {t('your_password_has_been_successfully_set')}
          </span>
        </div>
        <Button
          title={t('sign_in_to_account')}
          onClick={handleLoginRedirect}
          type="button"
          className="mx-auto block rounded-md px-3 py-2 text-center text-sm font-semibold text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-600 bg-gray-700 hover:bg-green-600"
        />
      </div>
    </div>
  );
};
