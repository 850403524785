import { lazy } from 'react';
import { SparklesIcon } from '@heroicons/react/20/solid';
import {
  ArrowTrendingUpIcon,
  PresentationChartBarIcon,
} from '@heroicons/react/24/outline';

const KpiDashboard = lazy(
  () => import('@cortex/admin/views/dashboards/kpi/kpi-dashboard'),
);
const Executive = lazy(
  () => import('@cortex/admin/views/dashboards/executive/executive'),
);
// const CustomerService = lazy(
//   () =>
//     import('@cortex/admin/views/dashboards/customer-service/customer-service'),
// );

export const dashboardsNavItems = [
  {
    icon: SparklesIcon,
    iconColor: 'text-green-700',
    iconBgColor: 'bg-green-100',
    name: 'sales',
    description: 'sales_description',
    path: '/dashboards/sales',
    routes: [
      {
        path: '/dashboards/sales',
        component: KpiDashboard,
      },
      {
        path: '/dashboards/sales/by/:group/for/:region',
        component: KpiDashboard,
      },
    ],
  },
  {
    icon: PresentationChartBarIcon,
    iconColor: 'text-purple-700',
    iconBgColor: 'bg-purple-100',
    name: 'executive_overview',
    description: 'executive_overview_description',
    path: '/dashboards/executive/overview',
    routes: [
      {
        path: '/dashboards/executive/:view',
        component: Executive,
      },
      {
        path: '/dashboards/executive/:view/for/:range',
        component: Executive,
      },
    ],
  },
  {
    icon: ArrowTrendingUpIcon,
    iconColor: 'text-orange-500',
    iconBgColor: 'bg-orange-100',
    name: 'executive_performance',
    description: 'executive_performance_description',
    path: '/dashboards/executive/performance',
  },
  // {
  //   icon: HomeModernIcon,
  //   iconColor: 'text-orange-500',
  //   iconBgColor: 'bg-orange-100',
  //   name: 'customer_service',
  //   description: 'customer_service_description',
  //   path: '/dashboards/customer-service',
  //   routes: [
  //     {
  //       path: '/dashboards/customer-service/:start?/:end?',
  //       component: CustomerService,
  //     },
  //   ],
  // },
];
