import { Suspense } from 'react';
import {
  FlagsmithProvider,
  LoadingSpinner,
  NavProvider,
  Session,
  Toast,
} from '@cortex/shared';
import { NestedProviders, Providers } from '@simian-creative/providers';

import { config } from './config/vars';
import { Router } from './router';

import './app.module.scss';

const providers: NestedProviders = [
  [NavProvider, config.nav],
  [FlagsmithProvider, { ...config.flagsmith, identity: Session.identity }],
];

export function App() {
  return (
    <>
      <Toast />

      <Suspense fallback={<LoadingSpinner />}>
        <Providers providers={providers}>
          <Router />
        </Providers>
      </Suspense>
    </>
  );
}

export default App;
