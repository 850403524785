import { IntializeFeaturesParams } from '../../features/initialize-features';

export function usePagable({
  resultRef,
  handleEvent,
}: IntializeFeaturesParams) {
  return {
    pagination: {
      totalItems: resultRef.current.total,
      itemsPerPage: resultRef.current.size,
      currentPage: resultRef.current.current_page,
      onChange: (page: number) => {
        handleEvent({
          trigger: 'pagable',
          page,
        });
      },
    },
  };
}
