import { lazy } from 'react';
import { UsersIcon } from '@heroicons/react/20/solid';
import { CurrencyDollarIcon } from '@heroicons/react/24/outline';

const MerchantsContracts = lazy(
  () => import('@cortex/admin/views/merchants/contracts/list/list'),
);

const MerchantContractDetails = lazy(
  () => import('@cortex/admin/views/merchants/contracts/details/details'),
);

const MerchantsContractsCreate = lazy(
  () => import('@cortex/admin/views/merchants/contracts/create/create'),
);
const MerchantSales = lazy(
  () => import('@cortex/admin/views/merchants/sales/sales'),
);

const InProgressMerchantSales = lazy(
  () => import('@cortex/admin/views/merchants/in-progress-sales/sales'),
);

export const merchantsNavItems = [
  {
    icon: CurrencyDollarIcon,
    iconColor: 'text-yellow-500',
    iconBgColor: 'bg-yellow-100',
    name: 'merchant_sales_in_progress',
    description: 'merchant_sales_description_v2',
    path: '/dashboards/merchants/sales/',
    flag: { key: 'redesigned_merchant_sales', enabled: true },
    routes: [
      {
        path: '/dashboards/merchants/sales/:type?/:label?/:index?/:start?/:end?/:merchant?',
        component: InProgressMerchantSales,
      },
    ],
  },
  {
    icon: CurrencyDollarIcon,
    iconColor: 'text-yellow-500',
    iconBgColor: 'bg-yellow-100',
    name: 'merchant_sales',
    description: 'merchant_sales_description',
    path: '/merchants/sales',
    flag: { key: 'redesigned_merchant_sales', enabled: false },
    routes: [
      {
        path: '/merchants/sales',
        component: MerchantSales,
      },
    ],
  },
  {
    icon: UsersIcon,
    name: 'navigation.merchants.contracts_list',
    description: 'navigation.merchants.contracts_list_description',
    path: '/merchants/contracts',
    routes: [
      {
        path: '/merchants/contracts',
        component: MerchantsContracts,
      },
      {
        path: '/merchants/contracts/:id',
        component: MerchantContractDetails,
      },
    ],
  },
  {
    icon: UsersIcon,
    name: 'navigation.merchants.contract_create',
    description: 'navigation.merchants.contract_create_description',
    path: '/merchants/contracts/create',
    routes: [
      {
        path: '/merchants/contracts/create',
        component: MerchantsContractsCreate,
      },
    ],
  },
];
