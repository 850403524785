import React, { useState } from 'react';
import { SlideOver } from '@cortex/shared';
import { MenuMobileNavCategory } from '@cortex/shared/components/menu-mobile-nav-category/menu-mobile-nav-category';
import { MobileNavHeader } from '@cortex/shared/components/navbar/components/mobile-nav-header';
import { NavCategoryType } from '@cortex/shared/models';
import { XMarkIcon } from '@heroicons/react/24/outline';

interface MobileNavProps {
  categories: NavCategoryType[];
  open: boolean;
  setOpen: (open: boolean) => void;
  logoutHandler: () => void;
}

export const MobileNav = ({
  categories,
  open,
  setOpen,
  logoutHandler,
}: MobileNavProps) => {
  const userNavigation = [{ name: 'Sign Out', href: '/', cb: logoutHandler }];

  const [categoryStates, setCategoryStates] = useState(
    Object.fromEntries(categories.map(category => [category.name, true])),
  );

  const toggleCategory = (categoryName: string) => {
    setCategoryStates(prevState => ({
      ...prevState,
      [categoryName]: !prevState[categoryName],
    }));
  };

  return (
    <div>
      <SlideOver open={open} setOpen={setOpen} slideBelowHeader={false}>
        <div className="flex h-full flex-col overflow-y-scroll bg-white shadow-xl">
          <div className="px-4 sm:px-6 py-4 border-b-2">
            <div className="flex items-center justify-between">
              <div className="font-semibold text-gray-900">
                <MobileNavHeader />
              </div>

              <div className="flex items-center">
                <button
                  type="button"
                  className="relative rounded-md bg-white text-gray-400 hover:text-reguard-gray focus:outline-none focus:ring-offset-2"
                  onClick={() => setOpen && setOpen(false)}
                >
                  <span className="sr-only">Close panel</span>
                  <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                </button>
              </div>
            </div>
          </div>
          <div className="relative mt-6 flex-1">
            <div className="space-y-1 pb-4 pt-2">
              {categories.map(category => (
                <MenuMobileNavCategory
                  key={category.name}
                  category={category}
                  toggleCategory={toggleCategory}
                  categoryStates={categoryStates}
                  setOpen={setOpen}
                />
              ))}
              {userNavigation.map(item => (
                <button
                  key={item.name}
                  onClick={item.cb}
                  type="submit"
                  className="flex w-[90%] mx-5 justify-center relative top-20 rounded-md bg-gray-700 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-emerald-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-emerald-400"
                >
                  {item.name}
                </button>
              ))}
            </div>
          </div>
        </div>
      </SlideOver>
    </div>
  );
};
