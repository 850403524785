import { Session } from '@cortex/shared';
import { FilterConfig, SelectedOption } from '@cortex/shared';

export function merchantIdDefaultBuilder(
  config: FilterConfig,
): SelectedOption | null {
  const { componentProps = {} } = config;
  const defaultMerchantId = componentProps.defaultMerchantId;

  if (!defaultMerchantId) {
    return null;
  }

  const merchant = Object.entries(Session.merchants).find(
    ([_, id]) => id === defaultMerchantId,
  );

  if (!merchant) {
    return null;
  }

  return {
    config: config,
    value: defaultMerchantId,
    value_name: merchant[0],
  };
}
