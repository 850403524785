import { dateFromUrl, getNumDaysInMonth } from '@cortex/shared';
import dayjs, { ManipulateType } from 'dayjs';

export function getMaxDate(interval: string) {
  let day = dayjs()
    .subtract(1, interval as ManipulateType)
    .endOf(interval as ManipulateType);

  if (interval === 'week') {
    day = day.add(1, 'day');
  }

  return day.toDate();
}

export function getRangeOrMaxDate(range: string, interval: string) {
  if (range !== '') {
    return dateFromUrl(range).date;
  }

  return getMaxDate(interval);
}

export function formatForMonth(month: number, year: number) {
  const start = new Date(year, month);
  const end = new Date(year, month, getNumDaysInMonth(year, month));

  return {
    startDate: start,
    endDate: end,
    key: 'selection',
  };
}
