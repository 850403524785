import { lazy } from 'react';
import {
  DocumentDuplicateIcon,
  DocumentTextIcon,
  UserGroupIcon,
  UsersIcon,
} from '@heroicons/react/20/solid';

const AccountsList = lazy(
  () => import('@cortex/admin/views/management/accounts/list/list'),
);
const AccountDetails = lazy(
  () => import('@cortex/admin/views/management/accounts/details/details'),
);
const ContractsList = lazy(
  () => import('@cortex/admin/views/management/contracts/list/list'),
);
const OrdersList = lazy(
  () => import('@cortex/admin/views/management/orders/list/list'),
);
const OrderDetails = lazy(
  () => import('@cortex/admin/views/management/orders/details/details'),
);
const OrdersAdd = lazy(
  () => import('@cortex/admin/views/management/orders/add/add'),
);
const OrdersCreate = lazy(
  () => import('@cortex/admin/views/management/orders/create/create'),
);
const UsersList = lazy(
  () => import('@cortex/admin/views/management/users/list/list'),
);

export const managementNavItems = [
  {
    icon: UsersIcon,
    name: 'Accounts',
    description: 'accounts',
    path: '/management/accounts',
    routes: [
      {
        path: '/management/accounts',
        component: AccountsList,
      },
      {
        path: '/management/accounts/:id',
        component: AccountDetails,
      },
    ],
  },
  {
    icon: DocumentDuplicateIcon,
    name: 'Contracts',
    description: 'contracts_description',
    path: '/management/contracts',
    routes: [
      {
        path: '/management/contracts',
        component: ContractsList,
      },
    ],
  },
  {
    icon: UserGroupIcon,
    name: 'Users',
    description: 'users_description',
    path: '/management/users',
    routes: [
      {
        path: '/management/users',
        component: UsersList,
      },
    ],
  },
  {
    icon: DocumentTextIcon,
    name: 'Orders',
    description: 'ipa_orders',
    path: '/management/orders',
    routes: [
      {
        path: '/management/orders',
        component: OrdersList,
      },
      {
        path: '/management/orders/create',
        component: OrdersCreate,
      },
      {
        path: '/management/orders/:id',
        component: OrderDetails,
      },
      {
        path: '/management/orders/:id/add',
        component: OrdersAdd,
      },
    ],
  },
];
