import { useResetPassword } from '../../hooks/useResetPassword';
import { secureEmailDisplay } from '../../utils/email-formatters';
import { TokenForm } from '../token-form/token-form';

interface VerificationCodeProps {
  email: string;
}

export const VerificationCode = ({ email }: VerificationCodeProps) => {
  const {
    customerToken,
    error,
    setError,
    codeSent,
    isLoading,
    handleVerifyEmail,
    handleUpdateVerifyEmail,
    navigate,
    setCodeSent,
    t,
  } = useResetPassword();

  async function onSubmit(code: string) {
    try {
      await handleUpdateVerifyEmail(email, code, customerToken);
    } catch (e) {
      if (e instanceof Error) {
        return setError(e.message);
      }
    }
  }

  async function handleResendCode() {
    setError('');
    setCodeSent(false);
    try {
      await handleVerifyEmail(email);
      setCodeSent(true);
    } catch (e) {
      if (e instanceof Error) {
        return setError(e.message);
      }
    }
  }

  const handleClose = (e: { preventDefault: () => void }) => {
    e.preventDefault();
    navigate('/', { replace: true });
  };

  return (
    <div className="md:px-[33%] py-10 md:py-24">
      <span className="mb-[3.125rem]">
        {t('please_enter_the_verification_code_we_just_sent_to', {
          value: secureEmailDisplay(email),
        })}
        .
      </span>

      <TokenForm error={error} setError={setError} handleSubmit={onSubmit}>
        <div className="pt-4 relative w-full flex flex-col items-center">
          {error && <div className="c1 text-red-600 mb-4">{error}</div>}

          {codeSent && (
            <div className="text-reguard-wintergreen-shade">
              {t('code_sent')}
            </div>
          )}

          <button
            disabled={isLoading || !!error}
            type="submit"
            className="mt-3 inline-flex w-[90%] items-center justify-center rounded-md bg-gray-700 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-[#02b78e] focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-[#02b78e] sm:ml-3 sm:mt-0 sm:w-auto"
            onClick={handleResendCode}
          >
            {t('resend_code')}
          </button>

          <button
            onClick={handleClose}
            className="mt-4 mb-[5.18rem] text-center hover:bg-red-600"
          >
            {t('cancel')}
          </button>
        </div>
      </TokenForm>
    </div>
  );
};
