import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import { NavCategoriesChildrenType } from '@cortex/shared/models';
import classix from 'classix';

interface TileLinkProps {
  link: NavCategoriesChildrenType;
  className?: string;
}

export function TileLink({ link, className }: TileLinkProps) {
  const { t } = useTranslation();

  return (
    <NavLink
      key={link.name}
      to={link.path}
      className={({ isActive }) =>
        classix(
          'flex items-center bg-white p-6',
          'focus-within:ring-2 focus-within:ring-inset focus-within:ring-white',
          'border rounded-lg gap-6',
          'hover:border-gray-300',
          'hover:shadow-sm',

          isActive && 'text-blue-500',
          className,
        )
      }
    >
      <div
        className={classix(
          'flex flex-none items-center justify-center',
          'h-8 w-8 ',
        )}
      >
        {link && link.icon && (
          <link.icon className="h-6 w-6 text-emerald-400" aria-hidden="true" />
        )}
      </div>

      <div>
        <div className="font-medium text-sm">{t(link.name)}</div>

        <p className="mt-2 text-sm font-normal">{t(link.description)}</p>
      </div>
    </NavLink>
  );
}
