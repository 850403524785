import { SelectedOption } from './types';

export function handleAdd(
  selectedOption: SelectedOption,
  selectedOptions: SelectedOption[],
) {
  let options = [...selectedOptions];

  // find current
  const alreadySelected = options.find(
    option => option.config.name === selectedOption.config.name,
  );

  // update its value if it's already selected
  if (alreadySelected) {
    alreadySelected.value = selectedOption.value;
    alreadySelected.value_name = selectedOption.value_name;
  }

  // if it's not already selected, add it
  if (!alreadySelected) {
    options = [...options, selectedOption];
  }

  return options;
}
