export const formInputLabelClassName =
  'block text-sm font-medium leading-6 text-gray-900';

export const formInputClassName =
  "block w-full rounded-md border-0 py-1.5 pl-4 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6'";

export const disabledFormInputClassName =
  "block w-full rounded-md border-0 py-1.5 pl-4 bg-gray-100 text-gray-400 shadow-sm cursor-not-allowed ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6'";

export const searchInputClassName =
  'block w-full rounded-md border-0 py-1.5 pl-10 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6';
