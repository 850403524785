import { lazy } from 'react';
import { BrowserRouter, Route } from 'react-router-dom';
import { apiLogin, loginDevice } from '@cortex/requests';
import {
  ForgotPassword,
  generatedRoutes,
  Login,
  PageNotFound,
  PasswordSuccessfullySet,
  ProtectedRoute,
  SentryRoutes,
  SetPassword,
} from '@cortex/shared';

import { navItems } from './config/nav-items';

const DashLinks = lazy(() => import('./views/dash-links/dash-links'));
const OcularDashboard = lazy(() => import('./views/dashboards/ocular/ocular'));

export function Router() {
  return (
    <BrowserRouter>
      <SentryRoutes>
        <Route
          path="/auth/login"
          element={
            <Login loginHandler={apiLogin} deviceLoginHandler={loginDevice} />
          }
        />

        <Route path="/forgot-password" element={<ForgotPassword />} />

        <Route path="/set-password/:customerToken" element={<SetPassword />} />

        <Route
          path="/set-password/success"
          element={<PasswordSuccessfullySet />}
        />

        <Route path="/" element={<ProtectedRoute component={DashLinks} />} />

        {generatedRoutes(navItems)}

        <Route
          path="/dashboards/ocular"
          element={<ProtectedRoute component={OcularDashboard} />}
        />

        <Route path="*" element={<PageNotFound />} />
      </SentryRoutes>
    </BrowserRouter>
  );
}
