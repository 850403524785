import { useEffect, useState } from 'react';
import { Navigate, useLocation } from 'react-router-dom';

import { Session } from '../../utils';
import { LoadingSpinner } from '../loading-spinner/loading-spinner';

interface ProtectedProps {
  // We could never know
  // eslint-disable-next-line
  children?: any;
  // eslint-disable-next-line
  component?: any;
}

const Protection = ({ children }: ProtectedProps) => {
  const { pathname } = useLocation();

  const [loading, setLoading] = useState(true);
  const [isValid, setValid] = useState(false);

  const ifValid = (valid: boolean) => {
    setValid(valid);
    setLoading(false);
  };

  useEffect(() => {
    ifValid(Session.isValid());
  }, [pathname]); // eslint-disable-line

  if (isValid) {
    return children;
  }

  if (loading) {
    return <LoadingSpinner />;
  }

  const to = {
    pathname: '/auth/login',
    state: {
      returnurl: pathname,
    },
  };

  return <Navigate to={to} />;
};

export function ProtectedRoute({ component: Component }: ProtectedProps) {
  return (
    <Protection>
      <Component />
    </Protection>
  );
}
