import dayjs from 'dayjs';
import jwt_decode from 'jwt-decode';
import _ from 'lodash';

interface DecodedToken {
  exp: number;
  permissions: string[];
}

export interface Merchant {
  id: string;
  name: string;
}

export interface MerchantConfig {
  has_non_plan_orders: boolean;
  has_written_and_invoiced: boolean;
}

export const Session = {
  isValid() {
    try {
      const decoded = this.decodedToken;

      if (!decoded) return false;

      return dayjs.unix(decoded.exp) > dayjs();
    } catch (err) {
      console.error(err);
      return false;
    }
  },

  set(name: string, value: unknown) {
    localStorage.setItem(name, JSON.stringify(value));
  },

  get(key: string) {
    const value = localStorage.getItem(key);

    if (!value) return null;

    try {
      return JSON.parse(value);
    } catch (err) {
      console.warn(err);
      return value;
    }
  },

  remove(key: string) {
    localStorage.removeItem(key);
  },

  get decoded() {
    return Session.get('session');
  },

  get decodedToken(): DecodedToken {
    const session = Session.get('session');
    return jwt_decode(session?.token?.access_token);
  },

  get identity(): string {
    return _.get(this, 'decoded.user.email');
  },

  get merchants() {
    const byName: { [name: string]: string } = {};

    const merchants = _.get(this, 'decoded.user.merchants', []);
    merchants.forEach((merchant: Merchant) => {
      byName[merchant.name] = merchant.id;
    });

    return byName;
  },

  get merchantIds() {
    return Object.values(this.merchants || {});
  },

  getMerchantsConfigs() {
    const merchants = _.get(this, 'decoded.user.merchants', []);
    return merchants.map(
      (merchant: { id: string; merchantConfig: MerchantConfig }) => ({
        merchant_id: merchant.id,
        merchant_config: merchant.merchantConfig,
      }),
    );
  },

  get permissions() {
    return this.decodedToken.permissions || [];
  },

  afterLogin(resp: SessionAuth) {
    const session = {
      user: resp.user,
      token: resp.token,
    };

    Session.set('session', session);
  },

  async afterLoginRedirect(authResult: SessionAuth, redirectTo: string) {
    Session.afterLogin(authResult);
    window.location.assign(redirectTo);
  },
};

export type SessionAuth = {
  user: unknown;
  token: unknown;
};
