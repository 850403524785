import { RangeKeyDict } from 'react-date-range';
import {
  FilterConfig,
  SelectedOption,
} from '@cortex/shared/components/filter-tags/helpers/types';

import { valueNameFormatter } from './value-formatter';

export function setupOnChange(
  config: FilterConfig,
  internalDate: RangeKeyDict,
  setInternalDate: (date: RangeKeyDict) => void,
  changeHandler: (selectedOption: SelectedOption) => void,
) {
  return (item: RangeKeyDict) => {
    const newDate = { ...internalDate, ...item };

    setInternalDate(newDate);

    const selectedOption = {
      config,
      value: newDate,
      value_name: valueNameFormatter(newDate),
    };

    changeHandler(selectedOption);
  };
}
