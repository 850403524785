import React from 'react';
import { useTranslation } from 'react-i18next';
import { useAuthorize } from '@cortex/shared/hooks/useAuthorize';
import { NavCategoryType } from '@cortex/shared/models';

interface NavCategoryProps {
  category: NavCategoryType;
  popoverOpen: boolean;
  setPopoverOpen: (popoverOpen: boolean) => void;
  selectedMenuCategory: string;
  setSelectedMenuCategory: (selectedMenuCategory: string) => void;
  handleSelected: (
    event: React.MouseEvent<HTMLElement> | React.FocusEvent<HTMLElement>,
    category: NavCategoryType,
  ) => void;
  isActive: boolean;
  children: React.ReactNode;
}

export const MenuNavCategory = ({
  category,
  handleSelected,
  isActive,
  children,
}: NavCategoryProps) => {
  const { t } = useTranslation();
  const isAuthorized = useAuthorize(category.requiredPermissions);

  if (!isAuthorized) return null;

  return (
    <div
      key={category.name}
      id="menu-dev"
      onFocus={event => handleSelected(event, category)}
      onMouseOver={event => handleSelected(event, category)}
      className={`relative rounded-md px-2 py-2 text-sm font-medium text-white hover:bg-gray-700 active:bg-gray-900 ${isActive ? 'bg-gray-900' : ''}`}
    >
      <span className="capitalize">
        {category.name ? t(category.name) : 'Solutions'}
      </span>

      {children}
    </div>
  );
};
