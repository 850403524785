import { Fragment } from 'react';
import { NavCategoriesChildrenType } from '@cortex/shared';
import { NavItem } from '@cortex/shared';
import { Transition } from '@headlessui/react';
import classix from 'classix';

interface MenuSubCategoryType {
  category: string;
  subcategoryData: NavCategoriesChildrenType[];
  popoverOpen: boolean;
  setPopoverOpen: (open: boolean) => void;
  selectedMenuCategory: string;
}

export const MenuSubCategory = ({
  category,
  subcategoryData,
  popoverOpen,
  setPopoverOpen,
  selectedMenuCategory,
}: MenuSubCategoryType) => {
  return (
    <Transition
      as={Fragment}
      show={popoverOpen && selectedMenuCategory === category}
      enter="transition ease-out duration-200"
      enterFrom="opacity-0 translate-y-1"
      enterTo="opacity-100 translate-y-0"
      leave="transition ease-in duration-150"
      leaveFrom="opacity-100 translate-y-0"
      leaveTo="opacity-0 translate-y-1"
    >
      <div className="absolute left-[-10px] z-10 top-10 flex w-screen max-w-max">
        <div
          onMouseLeave={() => setPopoverOpen(false)}
          className="w-screen max-w-md flex-auto overflow-hidden rounded-lg bg-white text-sm shadow-lg ring-1 ring-gray-900/5"
        >
          {subcategoryData.map((item: NavCategoriesChildrenType) => (
            <NavItem
              key={item.name}
              link={item}
              className={classix(
                'rounded-none border-t-white text-gray-800',
                'hover:border-[#e5e7eb] hover:border-t-white hover:bg-gray-50',
              )}
            />
          ))}
        </div>
      </div>
    </Transition>
  );
};
