import React from 'react';
import { useResetPassword } from '@cortex/shared/hooks/useResetPassword';

export const PasswordRequirements = () => {
  const { t } = useResetPassword();

  return (
    <span className="mb-8">
      {t(
        'password_must_be_at_least_8_characters_long_and_contain_one_character_from_each_of_the_following',
      )}
      <ul className="tw-cst-pf list-disc ml-6 mb-6">
        <li>{t('uppercase_(a,_z)')}</li>
        <li>{t('lowercase_(a,_z)')}</li>
        <li>{t('numeric_(0-9)')}</li>
        <li>{t('special_(!,_%,_@,_#,_etc.)')}</li>
      </ul>
    </span>
  );
};
