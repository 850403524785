import { TileLink } from '@cortex/shared';
import { useAuthorize } from '@cortex/shared/hooks/useAuthorize';
import { NavCategoriesChildrenType } from '@cortex/shared/models';
import { useFlags } from 'flagsmith/react';
import _ from 'lodash';

interface NavItemProps {
  link: NavCategoriesChildrenType;
  className?: string;
}

export const NavItem = ({ link, className }: NavItemProps) => {
  const isAuthorized = useAuthorize(link.requiredPermissions);
  const { name, flag } = link;
  const { key, enabled } = flag ?? {};

  const flags = useFlags([key ?? '']);

  if (!isAuthorized) return null;
  if (link.flag && _.get(flags, `${key}.enabled`) !== enabled) return null;

  return <TileLink key={name} link={link} className={className} />;
};
