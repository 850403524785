import { SelectedOption } from './types';

export function handleRemove(
  selectedOption: SelectedOption,
  selectedOptions: SelectedOption[],
) {
  const selectedOptionName = selectedOption.config.name;
  const selectedOptionRemovable = selectedOption.config.removable;

  // if option is not removable, return selectedOptions
  if (!selectedOptionRemovable) {
    return selectedOptions;
  }

  // remove filter value from selectedFilters by filterName
  return selectedOptions.filter(
    option => option.config.name !== selectedOptionName,
  );
}
