import { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react';
import { PaginationResponse } from '@cortex/shared/models';

import { ChangeParams, DataTableContextConfig } from '../data-table-context';
import {
  FeatureEventHandlerParams,
  initalizeFeatures,
} from './initialize-features';
import { useChangeParams } from './use-change-params';

export function useFeatures(config: Partial<DataTableContextConfig>) {
  const [params, setParams] = useChangeParams();
  const [result, setResult] = useState({} as PaginationResponse<unknown>);

  const resultRef = useRef<PaginationResponse<unknown>>(result);
  resultRef.current = result;

  const handleEvent = setupHandleEvent(setParams);
  const handleResult = setupHandleResult(config, params, setResult);

  useEffect(() => {
    handleResult();
  }, [params]); // eslint-disable-line react-hooks/exhaustive-deps

  return {
    result,
    features: initalizeFeatures({ params, handleEvent, config, resultRef }),
  };
}

function setupHandleResult(
  config: Partial<DataTableContextConfig>,
  params: ChangeParams,
  setResult: Dispatch<SetStateAction<PaginationResponse<unknown>>>,
) {
  return async function handleResult() {
    if (!config.onChange) return;

    const response = await config.onChange(params);
    if (!response) return;

    setResult(response);
  };
}

function setupHandleEvent(setParams: Dispatch<SetStateAction<ChangeParams>>) {
  return function (newParams: FeatureEventHandlerParams) {
    setParams((prev: ChangeParams) => {
      const params =
        typeof newParams === 'function' ? newParams(prev) : newParams;

      const reset = setupReset(setParams);

      return {
        ...prev,
        ...params,
        reset,
      };
    });
  };
}

function setupReset(setParams: Dispatch<SetStateAction<ChangeParams>>) {
  return function reset(resetParams: Partial<ChangeParams>) {
    setParams(prev => ({ ...prev, ...resetParams, trigger: 'reset' }));
  };
}
