import { dashboardsNavItems } from '@cortex/admin/config/dashboard-nav-items';
import { managementNavItems } from '@cortex/admin/config/management-nav-items';
import { NavItemType } from '@cortex/shared';

import { merchantsNavItems } from './merchants-nav-items';

const managementPermissions = ['access:management_admin'];
const merchantsPermissions = ['access:merchant_admin'];
const execLevels = ['access:exec_levels'];

export const navItems: NavItemType[] = [
  {
    name: 'home',
    path: '/',
    categories: [
      {
        name: 'dashboards',
        path: '',
        children: dashboardsNavItems,
        requiredPermissions: execLevels,
      },
      {
        name: 'management',
        path: '',
        children: managementNavItems,
        requiredPermissions: managementPermissions,
      },
      {
        name: 'merchants',
        path: '',
        children: merchantsNavItems,
        requiredPermissions: merchantsPermissions,
      },
    ],
  },
];
