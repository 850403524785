export enum Tags {
  DROPDOWN_PER_PAGE = 'dropdownPerPage',
  BTN_SEARCH = 'btnSearch',
  BTN_CLEAR = 'btnClear',
  BTN_CANCEL = 'btnCancel',
  BTN_SAVE = 'btnSave',
  BTN_EDIT = 'btnEdit',
}

export enum InputTags {
  EMAIL_SEARCH_INPUT = 'emailSearchInput',
  CONTRACT_SEARCH_INPUT = 'contractSearchInput',
  ORDER_SEARCH_INPUT = 'orderSearchInput',
}
