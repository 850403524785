export interface ButtonProps {
  title: string;
  type?: 'button' | 'submit' | 'reset' | undefined;
  id?: string;
  onClickHandler?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  className?: string;
  dataTestId?: string;
  icon?: JSX.Element;
}

const defaultClassName = [
  'rounded-md',
  'bg-white',
  'px-2.5',
  'py-1.5',
  'text-sm',
  'font-medium',
  'shadow-sm',
  'ring-1',
  'ring-inset',
  'ring-gray-300',
  'hover:bg-gray-50',
  'flex',
  'gap-1',
];

export function Button({
  title,
  type = 'button',
  id = '',
  onClick,
  onClickHandler,
  className = defaultClassName.join(' '),
  dataTestId,
  icon,
}: ButtonProps) {
  return (
    <button
      type={type}
      id={id}
      onClick={event => {
        if (onClick) {
          onClick(event);
        }

        if (onClickHandler) {
          onClickHandler(event);
        }
      }}
      className={className}
      data-testid={dataTestId}
    >
      {title}
      {icon}
    </button>
  );
}

export default Button;
