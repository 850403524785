import { useEffect, useRef, useState } from 'react';
import { PaginationResponse } from '@cortex/shared/models';

import { ChangeParams } from '../../data-table-context';
import {
  FeatureEventHandler,
  IntializeFeaturesParams,
} from '../../features/initialize-features';

export function useSelectable({
  resultRef,
  params,
  handleEvent,
}: IntializeFeaturesParams) {
  const [selectedItems, setSelectedItems] = useState([] as number[]);

  const paramsRef = useRef({} as ChangeParams);
  paramsRef.current = params;

  useEffect(() => {
    setSelectedItems(params.selectedItems);
  }, [params.selectedItems]);

  return {
    selection: {
      selectedItems,
      selectable: true,
      reset: paramsRef.current.trigger === 'reset',
      onChange: (rowIndex: number, checked: boolean) => {
        // if rowIndex is -1, then operate on all items
        if (checkAll(rowIndex, checked, resultRef, handleEvent)) return;

        // otherwise, add or remove the rowIndex from selectedItems
        checkSome(rowIndex, checked, resultRef, handleEvent);
      },
    },
  };
}

function checkAll(
  rowIndex: number,
  checked: boolean,
  resultRef: React.MutableRefObject<PaginationResponse<unknown>>,
  handleEvent: FeatureEventHandler,
) {
  if (rowIndex !== -1) return;

  const all = resultRef.current.content.map((_: unknown, i: number) => i);

  handleEvent({
    trigger: 'selectable',
    selectedItems: checked ? all : [],
    selectedData: checked ? resultRef.current.content : [],
  });

  return true;
}

function checkSome(
  rowIndex: number,
  checked: boolean,
  resultRef: React.MutableRefObject<PaginationResponse<unknown>>,
  handleEvent: FeatureEventHandler,
) {
  handleEvent((prev: ChangeParams) => {
    const prevItems = prev.selectedItems || [];

    const selectedItems = checked
      ? [...prevItems, rowIndex]
      : prevItems.filter(i => i !== rowIndex);

    const selectedData = resultRef.current.content.filter(
      (_: unknown, i: number) => selectedItems.includes(i),
    );

    return {
      ...prev,
      trigger: 'selectable',
      selectedItems,
      selectedData,
    };
  });
}
