import { useActionable } from '../components/actionable/add-actionable';
import { useFilterable } from '../components/filterable/add-filterable';
import { useLinkable } from '../components/linkable/add-linkable';
import { usePagable } from '../components/pagable/add-pageable';
import { useSearchable } from '../components/searchable/add-searchable';
import { useSelectable } from '../components/selectable/add-selectable';
import { IntializeFeaturesParams } from './initialize-features';

type Feature = (fParams: IntializeFeaturesParams) => Record<string, unknown>;

export const features: Record<string, Feature> = {
  searchable: useSearchable,
  selectable: useSelectable,
  filterable: useFilterable,
  actionable: useActionable,
  pagable: usePagable,
  linkable: useLinkable,
};
