import { Cog6ToothIcon } from '@heroicons/react/20/solid';

import { handleAdd } from './handle-add';
import { handleRemove } from './handle-remove';
import { Actions, BadgeItem, SelectedOption } from './types';

// VARIOUS HANDLERS SETUP FOR THE PROVIDER

export function setupChangeHandler(
  selectedOptions: SelectedOption[],
  setSelectedOptions: (options: SelectedOption[]) => void,
) {
  return (selectedOption: SelectedOption) => {
    const { value } = selectedOption;

    const options = value
      ? handleAdd(selectedOption, selectedOptions)
      : handleRemove(selectedOption, selectedOptions);

    setSelectedOptions(options);
  };
}

export function setupRemoveHandler(
  onSubmit: (selectedOptions: SelectedOption[]) => void,
  selectedOptions: SelectedOption[],
  setSelectedOptions: (options: SelectedOption[]) => void,
) {
  return (selectedOption: SelectedOption) => {
    const options = handleRemove(selectedOption, selectedOptions);
    setSelectedOptions(options);
    onSubmit(options);
  };
}

export function setupSubmitHandler(
  onSubmit: (selectedOptions: SelectedOption[]) => void,
  setOpen: (open: boolean) => void,
  selectedOptions: SelectedOption[],
) {
  return () => {
    onSubmit(selectedOptions);
    setOpen(false);
  };
}

export function setupBadgeHandler(selectedOptions: SelectedOption[]) {
  return (): BadgeItem[] => {
    const badges = selectedOptions.map(option => {
      return {
        name: option.config.name + ': ' + option.value_name,
        color: option.config.color,
        removable: option.config.removable,
        priority: option.config.priority,
        selectedOption: option,
      };
    });

    return badges.sort((a, b) => (a.priority || 0) - (b.priority || 0));
  };
}

export function setupActionsHandler(
  actions: Actions,
  setOpen: (open: boolean) => void,
) {
  return (): Actions => {
    const controls = {
      name: 'Controls',
      icon: Cog6ToothIcon,
      onClick: () => setOpen(true),
    };

    return [controls, ...actions];
  };
}
