import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { ChangeParams } from '../data-table-context';

const defaultChangeParams: ChangeParams = {
  page: 1,
  searchTerm: '',
  selectedItems: [],
  selectedData: [],
  refresh: false,
  action: '',
  filters: [],
  reset: (_: Partial<ChangeParams>) => {},
};

export function useChangeParams(): [
  ChangeParams,
  Dispatch<SetStateAction<ChangeParams>>,
] {
  const [urlSearchParams] = useSearchParams();
  const [params, setParams] = useState(paramsFromQuery(urlSearchParams));

  useEffect(() => {
    setParams((prev: ChangeParams) => ({
      ...prev,
      ...paramsFromQuery(urlSearchParams),
    }));
  }, [urlSearchParams]);

  return [params, setParams];
}

function paramsFromQuery(urlSearchParams: URLSearchParams): ChangeParams {
  return {
    ...defaultChangeParams,
    page: Number(urlSearchParams.get('page')) || 1,
    searchTerm: urlSearchParams.get('search') || '',
    filters: urlSearchParams.getAll('filters') || [],
  };
}
