import React from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import { useResetPassword } from '../../hooks/useResetPassword';
import { Tags } from '../../models/tags';
import { emailRules } from '../../utils/email-formatters';
import { Button } from '../button/button';
import { IfMatchingAccountMessage } from '../forgot-password/if-matching-account-message';
import { VerificationCode } from '../forgot-password/verification-code';
import { InputField } from '../input-field/input-field';

interface ForgotPasswordDataType {
  email: string;
}

export const ForgotPassword = () => {
  const {
    email,
    handleCancel,
    handleVerifyEmail,
    checkEmail,
    isSuccess,
    setEmail,
    t,
  } = useResetPassword();

  const schema = yup
    .object()
    .shape({
      email: yup
        .string()
        .required()
        .default('')
        .matches(emailRules, 'Please enter a valid email format.'),
    })
    .required();

  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm<ForgotPasswordDataType>({
    criteriaMode: 'all',
    // This one is a known issue - https://github.com/react-hook-form/resolvers/issues/245
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    resolver: yupResolver(schema),
  });

  const onSubmit = async (data: ForgotPasswordDataType) => {
    setEmail(data.email);
    await handleVerifyEmail(data.email);
  };

  const showResetPassInputs = !checkEmail && !isSuccess;

  if (showResetPassInputs) {
    return (
      <div className="bg-white sm:rounded-lg md:px-[33%] py-10">
        <div className="px-4 py-5 sm:p-6">
          <h3 className="text-2xl font-semibold leading-6 text-gray-900 text-center md:text-left">
            {t('reset_your_password')}
          </h3>
          <div className="mt-2 max-w-xl text-sm text-reguard-gray text-center md:text-left">
            <p>{t('enter_your_email_address')}</p>
          </div>
          <form
            className="mt-5 sm:flex sm:items-center"
            onSubmit={handleSubmit(onSubmit)}
          >
            <div className="w-full sm:max-w-xs">
              <InputField
                labelName="Email"
                elementName="email"
                isEditMode={true}
                register={register}
                type="email"
              />
              <span className="text-red-600" data-testid="test-id">
                {errors?.email?.message}
              </span>
            </div>

            <div className={`${errors?.email?.message ? 'mt-2' : 'mt-8'}`}>
              <Button
                title="Send Email"
                type="submit"
                className="inline-flex w-full items-center justify-center rounded-md bg-gray-700 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-[#02b78e] focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-[#02b78e] sm:ml-3 sm:mt-0 sm:w-auto"
                id={Tags.BTN_SAVE}
              />

              <Button
                title={t('cancel')}
                type="button"
                className="inline-flex w-full items-center justify-center rounded-md bg-gray-700 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-[#02b78e] sm:ml-3 sm:mt-0 sm:w-auto mt-4 md:mt-0"
                onClick={handleCancel}
              />
            </div>
          </form>
        </div>
      </div>
    );
  }

  if (checkEmail) {
    return <IfMatchingAccountMessage checkEmail={checkEmail} />;
  }

  if (isSuccess) {
    return (
      <div className="text-center">
        <VerificationCode email={email} />
      </div>
    );
  }
};
