import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { req } from '@cortex/requests';

import { isValidEmail } from '../utils/validation';

interface handleSetNewPasswordDataType {
  email: string;
  password: string;
  token: string;
}

interface SetPasswordResponse {
  token: string;
  created_at: string;
  complete: boolean;
}

export const useResetPassword = () => {
  const { customerToken } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [email, setEmail] = useState<string>('');
  const [error, setError] = useState<string>('');
  const [checkEmail, setCheckEmail] = useState<string | null>(null);

  const [codeSent, setCodeSent] = useState<boolean>(false);
  const [emailHasError, setEmailHasError] = useState<boolean>(false);
  const [isSuccess, setIsSuccess] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleVerifyEmail = async (email: string) => {
    if (!isValidEmail(email)) {
      return setEmailHasError(true);
    }

    const data = {
      email,
    };

    const params = {
      path: '/user/verify-email',
      method: 'POST',
      body: data,
    };

    try {
      setIsLoading(true);
      const res = await req<SetPasswordResponse>(params);

      if (res.complete) {
        setIsLoading(false);
        return setIsSuccess(true);
      }

      setIsLoading(false);
      setCheckEmail(email);
    } catch (err) {
      setIsLoading(false);
      setCheckEmail(email);
    }
  };

  const handleUpdateVerifyEmail = async (
    email: string,
    verificationCode: string,
    customerToken: string | undefined,
  ) => {
    if (!isValidEmail(email)) {
      return setEmailHasError(true);
    }

    const data = {
      email,
      verificationCode,
      customerToken,
    };

    const params = {
      path: '/user/verify-email',
      method: 'PATCH',
      body: data,
    };

    try {
      setIsLoading(true);
      const res = await req<SetPasswordResponse>(params);

      if (res && res.token) {
        setIsLoading(false);
        return navigate(`/set-password/${res.token}`, {
          replace: true,
        });
      }

      setIsLoading(false);
      setCheckEmail(email);
    } catch (err) {
      setError('Invalid verification code');
      setIsLoading(false);
      setCheckEmail(email);
    }
  };

  const handleSetNewPassword = async (data: handleSetNewPasswordDataType) => {
    const params = {
      path: '/user/set-password',
      method: 'PATCH',
      body: data,
    };

    try {
      setIsLoading(true);
      const res = await req<SetPasswordResponse>(params);

      if (res && res.created_at) {
        setIsLoading(false);

        return navigate('/set-password/success', {
          replace: true,
        });
      }
    } catch (err) {
      setError('Something went wrong');
      setIsLoading(false);
      setCheckEmail(email);
    }
  };

  const handleCancel = () => {
    navigate('/');
  };

  return {
    customerToken,
    t,
    email,
    error,
    setError,
    codeSent,
    setEmail,
    emailHasError,
    setEmailHasError,
    checkEmail,
    setCheckEmail,
    isLoading,
    isSuccess,
    navigate,
    setIsSuccess,
    handleCancel,
    handleSetNewPassword,
    handleVerifyEmail,
    handleUpdateVerifyEmail,
    setCodeSent,
  };
};
