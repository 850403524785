import { Fragment, ReactNode } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import classix from 'classix';

interface SlideOverProps {
  open: boolean;
  setOpen?: (open: boolean) => void;
  slideBelowHeader?: boolean;
  children?: ReactNode;
  width?: string;
}

export const SlideOver = ({
  children,
  width = 'w-80',
  ...props
}: SlideOverProps) => {
  const { open = false, setOpen = () => {}, slideBelowHeader = true } = props;

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-10"
        onClose={() => setOpen && setOpen(false)}
      >
        <div className="fixed inset-0" />
        <div className="fixed inset-0 overflow-hidden">
          <div className="absolute inset-0 overflow-hidden">
            <div className="pointer-events-none fixed inset-y-0 right-0 flex pl-[72px] max-w-full md:max-w-[392px]">
              <Transition.Child
                as={Fragment}
                enter="transform transition ease-in-out duration-500 sm:duration-700"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-500 sm:duration-700"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              >
                <Dialog.Panel
                  className={classix(
                    `pointer-events-auto ${width} translate-x-0`,
                    `${
                      slideBelowHeader &&
                      'absolute bottom-0 right-0 h-[calc(100vh-66px)]'
                    }`,
                  )}
                >
                  {children}
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};
