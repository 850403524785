import { StrictMode } from 'react';
import * as ReactDOM from 'react-dom/client';
import { i18nInit } from '@cortex/locales';
import { initSentry } from '@cortex/shared';

import App from './app/app';
import { config } from './app/config/vars';

initSentry(config.sentry.dsn);
i18nInit();

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);

root.render(
  <StrictMode>
    <App />
  </StrictMode>,
);
