import { Session } from './session';

export const allMerchants = {
  name: 'All Merchants',
  id: '',
};

export const buildMerchants = (defaultMerchant = allMerchants) => {
  const userData = Session.decoded;
  const merchants = userData.user.merchants;
  merchants.unshift(defaultMerchant);

  return merchants;
};

export const emptyDataOrVal = (
  value: string | number | undefined | null,
  fallback = 'N/A',
) => {
  return value || fallback;
};
