export const intervals = [
  {
    name: 'Weekly',
    id: 'week',
  },
  {
    name: 'Monthly',
    id: 'month',
  },
];
