import Select from '@cortex/shared/components/select/select';

import { useFilter } from './helpers/use-filter';
import { BuiltFilterProps } from '../../../helpers/types';

export interface MerchantFilterProps {
  defaultMerchantId?: string;
}

export function MerchantFilter(props: BuiltFilterProps) {
  const { merchantId, merchants, onChange } = useFilter(props);
  return (
    <Select data={merchants} value={merchantId} onChangeHandler={onChange} />
  );
}
