import React from 'react';
import { useForm } from 'react-hook-form';
import { decodeToken } from 'react-jwt';
import { useParams } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import { useResetPassword } from '../../hooks/useResetPassword';
import { Tags } from '../../models/tags';
import { ResetPasswordInputs } from '../../models/types';
import { passwordRules } from '../../utils/password-rules';
import { Button } from '../button/button';
import { InputField } from '../input-field/input-field';
import { PasswordRequirements } from './password-requirements';

interface DataType {
  password: string;
}

export const SetPassword = () => {
  const { customerToken } = useParams();
  const { handleCancel, handleSetNewPassword, t } = useResetPassword();

  const schema = yup
    .object()
    .shape({
      password: yup
        .string()
        .required()
        .default('')
        .matches(
          passwordRules,
          t("your_password_doesn't_meet_the_requirements") as string,
        )
        .min(8, t('password_must_be_at_least_8_characters') as string),
      repeat: yup
        .string()
        .required()
        .default('')
        .when('password', {
          is: () => true,
          then: yup
            .string()
            .oneOf(
              [yup.ref('password')],
              t('please_make_sure_your_passwords_match') as string,
            ),
        }),
    })
    .required();

  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm<ResetPasswordInputs>({
    criteriaMode: 'all',
    // This one is a known issue - https://github.com/react-hook-form/resolvers/issues/245
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    resolver: yupResolver(schema),
  });

  const onSubmit = async (data: DataType) => {
    if (!customerToken) {
      throw new Error();
    }

    const token = atob(customerToken);

    const decodedToken: { email: string; reset?: boolean } | null =
      decodeToken(token);

    if (!decodedToken) {
      throw new Error();
    }

    const customerData = {
      email: decodedToken?.email,
      password: data.password,
      token: token,
    };

    await handleSetNewPassword(customerData);
  };

  return (
    <div className="bg-white sm:rounded-lg md:px-[33%] md:py-24">
      <div className="px-4 py-5 sm:p-6">
        <span className="mx-auto block font-bold text-xl text-center">
          {t('enter_a_new_password')}
        </span>

        <br />
        <br />

        <PasswordRequirements />

        <form
          className="bg-white sm:rounded-xl md:col-span-2"
          onSubmit={handleSubmit(onSubmit)}
        >
          <div>
            <div className="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
              <div className="sm:col-span-3">
                <InputField
                  labelName="New Password"
                  elementName="password"
                  isEditMode={true}
                  register={register}
                  type="password"
                />
                <span className="text-red-600" data-testid="test-id">
                  {errors?.password?.message}
                </span>
              </div>

              <div className="sm:col-span-3">
                <InputField
                  labelName="Repeat New Password"
                  elementName="repeat"
                  isEditMode={true}
                  register={register}
                  type="password"
                />
                <span className="text-red-600">{errors?.repeat?.message}</span>
              </div>

              <Button
                title="Save"
                type="submit"
                className="block rounded-md px-3 py-2 text-center font-semibold text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-600 text-xs bg-gray-700 hover:bg-green-600"
                id={Tags.BTN_SAVE}
              />

              <Button
                title={t('cancel')}
                type="button"
                className="block rounded-md px-3 py-2 text-center font-semibold text-white shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600 text-xs bg-gray-700"
                onClick={handleCancel}
              />
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};
