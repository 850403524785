import { IntializeFeaturesParams } from '../../features/initialize-features';

export function useActionable({
  config,
  handleEvent,
  params,
}: IntializeFeaturesParams) {
  return {
    action: {
      title: config.actions?.title,

      options: config.actions?.options.map(option => ({
        ...option,
        disabled: params.selectedItems.length === 0,
      })),

      onClick: (option: string) => {
        handleEvent({
          trigger: 'actionable',
          action: option,
        });
      },
    },
  };
}
