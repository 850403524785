import { useEffect, useState } from 'react';
import { useFilterTagsContext } from '@cortex/shared/components/filter-tags/helpers/filter-tags-context';
import {
  BuiltFilterProps,
  FilterOption,
} from '@cortex/shared/components/filter-tags/helpers/types';

import { setupOnChange } from './on-change';

export function useFilter(props: BuiltFilterProps) {
  const { selectedOptions, changeHandler } = useFilterTagsContext();
  const { config } = props;

  const disabled = config.disabled || false;
  const options = config.options || [];

  const option = selectedOptions.find(
    option =>
      option.config.kind === 'common' && option.config.name === config.name,
  );

  const firstOptionId = option?.value || options[0].id || '';
  const [optionId, setOption] = useState<string>(firstOptionId as string);

  const onChange = setupOnChange(config, setOption);

  useEffect(() => {
    const optionObj = options.find((opt: FilterOption) => opt.id === optionId);

    const selectedOption = {
      config,
      value: optionId,
      value_name: optionObj?.name,
    };

    changeHandler(selectedOption);
  }, [optionId]); // eslint-disable-line react-hooks/exhaustive-deps

  return {
    disabled,
    options,
    optionId,
    onChange,
  };
}
