import { req } from '../../base';

export interface LoginResp {
  user: unknown;
  token: { access_token: string; expires_in: number };
}

export const apiLogin = (email: string, password: string) => {
  const data = {
    email,
    password,
  };

  const params = {
    path: '/auth/login',
    method: 'POST',
    body: data,
  };

  return req<LoginResp>(params);
};
