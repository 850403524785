import { allMerchants } from '@cortex/shared';
import {
  FilterChangeEvent,
  FilterConfig,
  SelectedOption,
} from '@cortex/shared/components/filter-tags/helpers/types';
import { MerchantType } from '@cortex/shared/models';

export function setupOnChange(
  config: FilterConfig,
  changeHandler: (option: SelectedOption) => void,
  merchants: MerchantType[],
  setMerchant: (id: string) => void,
) {
  return async (event: FilterChangeEvent) => {
    if (!event) return setMerchant(allMerchants.id);

    const merchantId = event.target.value;
    const targetedMerchant = merchants.find((merchant: MerchantType) => {
      return merchant.id === merchantId;
    });

    setMerchant(targetedMerchant?.id || '');

    const option = {
      config,
      value: targetedMerchant?.id,
      value_name: targetedMerchant?.name,
    };

    changeHandler(option);
  };
}
