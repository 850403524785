import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import { NavCategoriesChildrenType } from '@cortex/shared';
import { useAuthorize } from '@cortex/shared/hooks/useAuthorize';

interface NavItemProps {
  link: NavCategoriesChildrenType;
  setOpen: (open: boolean) => void;
}
export const NavItemMobile = ({ link, setOpen }: NavItemProps) => {
  const { t } = useTranslation();
  const isAuthorized = useAuthorize(link.requiredPermissions);

  if (!isAuthorized) return null;

  return (
    <NavLink
      onClick={() => setOpen(false)}
      key={link.path}
      to={`${link.path}`}
      className={({ isActive }) =>
        isActive ? 'text-reguard-gray bg-[#eee] block' : 'text-reguard-gray'
      }
    >
      <div className="text-left text-lg xs:text-sm my-4 ml-[36px] p-3">
        {t(link.name)}
      </div>
    </NavLink>
  );
};
