import React, { useEffect, useState } from 'react';
import { SourceError } from '@cortex/shared';

import { handleDeviceLogin } from './helpers/handle-device-login';
import { isValidEmail, isValidPassword } from '../../utils';
import { Session, SessionAuth } from '../../utils/session';

type ErrorResponse = {
  message: string;
};

export const useLoginHandler = (
  loginHandler: (email: string, password: string) => Promise<unknown>,
  deviceLoginHandler: () => Promise<[unknown, string]>,
) => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [email, setEmail] = useState<string>('');
  const [emailHasError, setEmailHasError] = useState<boolean>(false);
  const [password, setPassword] = useState<string>('');
  const [passwordHasError, setPasswordHasError] = useState<boolean>(false);
  const [error, setError] = useState<string>('');
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  useEffect(() => {
    handleDeviceLogin(setIsLoading, deviceLoginHandler);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const handleLoginSubmission = async (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => {
    event.preventDefault();

    setEmailHasError(false);
    setPasswordHasError(false);
    setError('');

    if (!isValidEmail(email)) {
      setEmailHasError(true);
    }

    if (!isValidPassword(password)) {
      return setPasswordHasError(true);
    }

    setIsSubmitting(true);

    try {
      const resp = await loginHandler(email, password);
      Session.afterLoginRedirect(resp as SessionAuth, '/');

      setIsSubmitting(false);
    } catch (error) {
      const { data } = error as SourceError;

      setIsSubmitting(false);
      setError((data as ErrorResponse)?.message || 'An error occurred.');
    }
  };

  return {
    email,
    setEmail,
    emailHasError,
    setEmailHasError,
    password,
    setPassword,
    passwordHasError,
    setPasswordHasError,
    error,
    setError,
    isSubmitting,
    setIsSubmitting,
    handleLoginSubmission,
    isLoading,
  };
};
