import { useState } from 'react';
import { RangeKeyDict } from 'react-date-range';
import { useFilterTagsContext } from '@cortex/shared/components/filter-tags/helpers/filter-tags-context';
import { BuiltFilterProps } from '@cortex/shared/components/filter-tags/helpers/types';

import { defaultDate } from './default-value-builder';
import { setupOnChange } from './on-change';

export function useFilter(props: BuiltFilterProps) {
  const { selectedOptions, changeHandler } = useFilterTagsContext();
  const { config } = props;
  const { componentProps = {} } = config;

  const rangeColor = config.color as string;
  const initialDate = componentProps.initialDate;

  const selectedDate = selectedOptions.find(
    option =>
      option.config.kind === 'date-range' && option.config.name === config.name,
  );
  const initDateDict = selectedDate?.value || defaultDate(initialDate);
  const [internalDate, setInternalDate] = useState<RangeKeyDict>(
    initDateDict as RangeKeyDict,
  );

  const handleDateChange = setupOnChange(
    config,
    internalDate,
    setInternalDate,
    changeHandler,
  );

  return {
    rangeColor,
    internalDate,
    handleDateChange,
  };
}
