import { useMemo } from 'react';
import { Session } from '@cortex/shared';

export const useAuthorize = (requiredPermissions: string[] = []) => {
  const permissions = Session.permissions;

  return useMemo(() => {
    return requiredPermissions.every(permission =>
      permissions.includes(permission),
    );
  }, [permissions, requiredPermissions]);
};
