import { Range, RangeKeyDict } from 'react-date-range';
import {
  FilterConfig,
  SelectedOption,
} from '@cortex/shared/components/filter-tags/helpers/types';
import dayjs from 'dayjs';

import { valueNameFormatter } from './value-formatter';

export function defaultDate(initDate?: Range): RangeKeyDict {
  initDate = initDate || {
    startDate: dayjs().subtract(1, 'month').toDate(),
    endDate: new Date(),
  };

  return {
    selection: {
      key: 'selection',
      ...initDate,
    },
  };
}

export function dateRangeDefaultValueBuilder(
  config: FilterConfig,
): SelectedOption | null {
  const { componentProps = {} } = config;
  const initialDate = componentProps.initialDate;

  if (!initialDate) {
    return null;
  }

  const initDateDict = defaultDate(initialDate);

  return {
    config: config,
    value: initDateDict,
    value_name: valueNameFormatter(initDateDict),
  };
}
