import { PaginationResponse } from '@cortex/shared/models';

import { ChangeParams, DataTableContextConfig } from '../data-table-context';
import { features } from './features';

export type FeaturesEventCallback = (prev: ChangeParams) => ChangeParams;
export type FeatureEventHandlerParams =
  | Partial<ChangeParams>
  | FeaturesEventCallback;
export type FeatureEventHandler = (
  newParams: FeatureEventHandlerParams,
) => void;

export interface IntializeFeaturesParams {
  resultRef: React.MutableRefObject<PaginationResponse<unknown>>;
  params: ChangeParams;
  handleEvent: FeatureEventHandler;
  config: Partial<DataTableContextConfig>;
}

export function initalizeFeatures(fParams: IntializeFeaturesParams) {
  return Object.entries(features).reduce((acc, [key, hook]) => {
    if (!fParams.config[key as keyof DataTableContextConfig]) return acc;

    return { ...acc, ...hook(fParams) };
  }, {});
}
