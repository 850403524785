import dayjs from 'dayjs';

export function setNewUrl(interval: string, date: Date) {
  const intervalLetter = interval[0];
  const dateStr = dayjs(date).format('YYYY-MM-DD');
  const currentUrl = window.location.pathname;
  const [prefix] = currentUrl.split('/for');

  const newUrl = `${prefix}/for/${intervalLetter}-${dateStr}`;
  window.history.pushState({}, '', newUrl);
}
