import { RangeKeyDict } from 'react-date-range';
import { dateFormatter } from '@cortex/shared';

export function valueNameFormatter(date: RangeKeyDict) {
  return (
    dateFormatter(date.selection.startDate, 'MM/DD/YY') +
    ' - ' +
    dateFormatter(date.selection.endDate, 'MM/DD/YY')
  );
}
