import { IntializeFeaturesParams } from '../../features/initialize-features';

export function useSearchable({ handleEvent }: IntializeFeaturesParams) {
  return {
    search: {
      defaultSearchTerm: '',
      onSubmit: (searchTerm?: string) => {
        handleEvent({
          trigger: 'searchable',
          searchTerm,
        });
      },
    },
  };
}
