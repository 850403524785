import { Disclosure } from '@headlessui/react';
import { Bars3Icon } from '@heroicons/react/24/outline';

interface MobileMenuButtonProps {
  setOpen: (open: boolean) => void;
}

export const MobileMenuButton = ({ setOpen }: MobileMenuButtonProps) => {
  return (
    <div className="absolute inset-y-0 right-0 mr-4 flex items-center sm:hidden">
      <Disclosure.Button
        className="relative inline-flex items-center justify-center rounded-md p-2 text-white ml-8 focus:outline-none"
        onClick={() => setOpen(true)}
      >
        <span className="absolute -inset-0.5" />
        <span className="sr-only">Open main menu</span>
        <Bars3Icon className="block h-6 w-6" aria-hidden="true" />
      </Disclosure.Button>
    </div>
  );
};
