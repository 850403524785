interface DataType {
  name: string;
  id: string | null;
}

interface PropsType {
  data: Array<DataType>;
  value: string | null;
  disabled?: boolean;
  classes?: string;
  onChangeHandler: (x: React.ChangeEvent<HTMLSelectElement>) => void;
}

export function Select(props: PropsType) {
  const { data, value, onChangeHandler, classes } = props;

  const mockData = [
    { name: 'Mock Sold', id: '123' },
    { name: 'Mock Gross Revenue', id: '456' },
    { name: 'Mock Sales', id: '789' },
  ];

  const selectItems = data ? data : mockData;
  const val = value ? value : mockData[0].id;

  return (
    <div className={`${classes && classes}`}>
      <select
        disabled={props.disabled}
        className="block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6"
        value={val}
        onChange={event => onChangeHandler(event)}
      >
        {selectItems.map((a: DataType) => {
          return (
            <option key={a.name} value={a.id || undefined}>
              {a.name}
            </option>
          );
        })}
      </select>
    </div>
  );
}

export default Select;
