import { IntializeFeaturesParams } from '../../features/initialize-features';

export function useFilterable({
  config,
  handleEvent,
}: IntializeFeaturesParams) {
  return {
    filter: {
      filters: config.filters,
      onChange: (filters: string[]) => {
        handleEvent({
          trigger: 'filterable',
          filters,
        });
      },
    },
  };
}
