import { Fragment } from 'react';
import Gravatar from 'react-gravatar';
import { Session } from '@cortex/shared';
import { Menu, Transition } from '@headlessui/react';

type ProfileProps = {
  logoutHandler: () => void;
};

export const Profile = ({ logoutHandler }: ProfileProps) => {
  const userData = Session.decoded;
  const userEmail = userData?.user?.email || 'User Email';
  const userNavigation = [{ name: 'Sign out', href: '/', cb: logoutHandler }];

  function classNames(...classes: Array<string>) {
    return classes.filter(Boolean).join(' ');
  }

  return (
    <div className="hidden sm:block">
      <div className="ml-4 flex items-center md:ml-6">
        {/* Profile dropdown */}
        <Menu as="div" className="relative ml-3">
          <div>
            <Menu.Button className="flex max-w-xs items-center rounded-full bg-gray-800 text-sm focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800 mt-4">
              <span className="sr-only">Open user menu</span>
              <Gravatar
                className="rounded-full"
                size={30}
                default="mp"
                email={userEmail}
              />
            </Menu.Button>
          </div>
          <Transition
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <Menu.Items className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
              {userNavigation.map(item => (
                <Menu.Item key={item.name}>
                  {({ active }) => (
                    <div
                      role="presentation"
                      onKeyUp={item.cb}
                      onClick={item.cb}
                      className={classNames(
                        active ? 'bg-gray-100' : '',
                        'block px-4 py-2 text-sm text-gray-700',
                      )}
                    >
                      {item.name}
                    </div>
                  )}
                </Menu.Item>
              ))}
            </Menu.Items>
          </Transition>
        </Menu>
      </div>
    </div>
  );
};
