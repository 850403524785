import _ from 'lodash';

import { kinds } from './kinds';
import { FilterConfig } from './types';

export function buildDefaultSelectedOptions(configs: FilterConfig[]) {
  return _.compact(
    configs.map(config => {
      const kind = kinds[config.kind] || {};

      if (!kind.defaultBuilder) {
        return null;
      }

      return kind.defaultBuilder(config);
    }),
  );
}
