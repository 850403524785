import { Session, SessionAuth } from '../../../utils/session';

export async function handleDeviceLogin(
  setIsLoading: (loading: boolean) => void,
  deviceLoginHandler: () => Promise<[unknown, string]>,
) {
  try {
    const [authResult, redirectTo] = await deviceLoginHandler();
    Session.afterLoginRedirect(authResult as SessionAuth, redirectTo);

    setIsLoading(false);
  } catch (error) {
    setIsLoading(false);
  }
}
