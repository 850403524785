import { Trans } from 'react-i18next';

import { useResetPassword } from '../../hooks/useResetPassword';

interface IfMatchingAccountMessageProps {
  checkEmail: string;
}

export const IfMatchingAccountMessage = ({
  checkEmail,
}: IfMatchingAccountMessageProps) => {
  const { handleCancel } = useResetPassword();

  return (
    <div className="bg-white sm:rounded-lg md:px-[33%] py-10 md:py-24 mx-4">
      <h5 className="mt-2 text-xl">
        <Trans
          i18nKey="if_matching_account"
          checkEmail={checkEmail}
          data-testid="test-id"
        >
          {{ checkEmail }}
        </Trans>
      </h5>

      <button
        type="submit"
        className="inline-flex w-full items-center justify-center rounded-md bg-gray-700 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-emerald-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-[#02b78e] sm:w-auto mt-2"
        onClick={handleCancel}
      >
        Ok
      </button>
    </div>
  );
};
