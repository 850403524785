import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';

export const PageNotFound = () => {
  const { t } = useTranslation();

  return (
    <main className="grid min-h-full place-items-center bg-white px-6 py-10 md:py-24 sm:py-32 lg:px-8">
      <div className="text-center">
        <p className="text-2xl md:text-4xl font-semibold text-gray-700">404</p>
        <h1 className="mt-4 text-3xl font-bold tracking-tight text-gray-900 sm:text-5xl">
          {t('page_not_found')}
        </h1>
        <p className="mt-6 text-sm md:text-lg leading-7 text-gray-600">
          {t('page_not_found_subtitle')}
        </p>
        <div className="mt-10 flex items-center justify-center gap-x-6">
          <NavLink
            className="rounded-md bg-gray-700 hover:bg-green-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            to="/"
          >
            {t('go_back_home')}
          </NavLink>
        </div>
      </div>
    </main>
  );
};
