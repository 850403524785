import { DateRange as ReactDateRange, Range } from 'react-date-range';

import { useFilter } from './helpers/use-filter';
import { BuiltFilterProps } from '../../../helpers/types';

import '@szhsin/react-menu/dist/index.css';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';

export interface DateRangeFilterProps {
  rangeColor?: string;
  initialDate?: Range;
}

export function DateRangeFilter(props: BuiltFilterProps) {
  const { internalDate, handleDateChange, rangeColor } = useFilter(props);

  return (
    <ReactDateRange
      onChange={handleDateChange}
      moveRangeOnFirstSelection={false}
      months={1}
      ranges={[internalDate.selection]}
      direction="vertical"
      maxDate={new Date()}
      rangeColors={[rangeColor]}
    />
  );
}
