import React from 'react';

import {
  disabledFormInputClassName,
  formInputClassName,
  formInputLabelClassName,
} from '../../classes/inputClasses';
import { AccountDetailsInputs, ResetPasswordInputs } from '../../models/types';

interface InputFieldProps {
  labelName: string;
  elementName: keyof AccountDetailsInputs | keyof ResetPasswordInputs;
  type?: string;
  isEditMode: boolean;
  register: unknown;
}

export const InputField = ({
  labelName,
  elementName,
  type,
  isEditMode,
  register,
}: InputFieldProps) => (
  <div>
    <label htmlFor={elementName} className={formInputLabelClassName}>
      {labelName}
    </label>
    <div className="mt-2">
      <input
        type={type}
        id={elementName}
        disabled={!isEditMode}
        className={isEditMode ? formInputClassName : disabledFormInputClassName}
        // Revisit... we need this one to be very flexible
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        {...register(elementName)}
      />
    </div>
  </div>
);

export default InputField;
