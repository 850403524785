import { NavCategoryType } from '@cortex/shared';
import { NavItemMobile } from '@cortex/shared/components/nav-item-mobile/nav-item-mobile';
import { useAuthorize } from '@cortex/shared/hooks/useAuthorize';
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/20/solid';

interface MenuMobileNavCategoryProps {
  category: NavCategoryType;
  toggleCategory: (categoryName: string) => void;
  categoryStates: Record<string, boolean>;
  setOpen: (open: boolean) => void;
}

export const MenuMobileNavCategory = ({
  category,
  toggleCategory,
  categoryStates,
  setOpen,
}: MenuMobileNavCategoryProps) => {
  const isAuthorized = useAuthorize(category.requiredPermissions);

  if (!isAuthorized) return null;

  return (
    <div key={category.name}>
      <div
        className="flex justify-between"
        onClick={() => toggleCategory(category.name)}
        role="button"
        tabIndex={0}
        onKeyDown={e => {
          if (e.key === 'Enter' || e.key === ' ') {
            toggleCategory(category.name);
          }
        }}
      >
        <p className="capitalize text-reguard-gray text-lg xs:text-sm ml-8">
          {category.name}
        </p>
        {categoryStates[category.name] ? (
          <ChevronDownIcon className="block h-6 w-6 mx-4" aria-hidden="true" />
        ) : (
          <ChevronUpIcon className="block h-6 w-6 mx-4" aria-hidden="true" />
        )}
      </div>
      {categoryStates[category.name] &&
        category.children.map(link => (
          <NavItemMobile key={link.name} link={link} setOpen={setOpen} />
        ))}
    </div>
  );
};
