import { config as commonConfig, logoutHandler } from '@cortex/requests';

import rawFlags from '../../assets/flagsmith.json';
import { navItems } from './nav-items';

export const config = {
  ...commonConfig,

  merchants: {
    defaultAccountNumber:
      process.env.VITE_MERCHANTS_DEFAULT_ACCOUNT_NUMBER ||
      'noreply@reguardprotection.com',
  },

  sentry: {
    dsn: process.env.VITE_SENTRY_ADMIN_DSN || '',
  },

  nav: {
    logoutHandler: logoutHandler,
    navItemConfig: navItems,
  },

  flagsmith: {
    rawFlags,
    environmentID: process.env.VITE_FLAGSMITH_ENVIRONMENT || '',
    api:
      process.env.VITE_FLAGSMITH_API ||
      'https://flagsmith.reguardprotection.com/api/v1/',
  },
};
