import Select from '@cortex/shared/components/select/select';

import { useFilter } from './helpers/use-filter';
import { BuiltFilterProps } from '../../../helpers/types';

export function CommonFilter(props: BuiltFilterProps) {
  const { disabled, optionId, options, onChange } = useFilter(props);

  return (
    <Select
      data={options}
      value={optionId}
      onChangeHandler={onChange}
      disabled={disabled}
    />
  );
}
