import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { LoadingSpinner } from '../loading-spinner/loading-spinner';
import { useLoginHandler } from './hook';

type LoginProps = {
  loginHandler: (email: string, password: string) => Promise<unknown>;
  deviceLoginHandler: () => Promise<[unknown, string]>;
};

export const Login = ({ loginHandler, deviceLoginHandler }: LoginProps) => {
  const {
    email,
    setEmail,
    emailHasError,
    password,
    setPassword,
    passwordHasError,
    error,
    isSubmitting,
    handleLoginSubmission,
    isLoading,
  } = useLoginHandler(loginHandler, deviceLoginHandler);

  const { t } = useTranslation('admin');

  const navigate = useNavigate();

  const handleResetPassword = (event: React.MouseEvent<HTMLAnchorElement>) => {
    event.preventDefault();
    return navigate('/forgot-password');
  };

  if (isLoading) return <LoadingSpinner />;

  return (
    <div className="flex min-h-full flex-col justify-center px-6 py-12 lg:px-8">
      <div className="sm:mx-auto sm:w-full sm:max-w-sm">
        <h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-reguard-dark-gray">
          {t('sign_in_to_account')}
        </h2>
      </div>

      <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
        <form className="space-y-6">
          <div>
            <label
              htmlFor="email"
              className="block text-sm font-medium leading-6 text-reguard-dark-gray"
            >
              {t('email_address')}
            </label>
            <div className="mt-2">
              <input
                id="email"
                name="email"
                type="email"
                autoComplete="email"
                required
                className={`'block w-full rounded-md border-0 py-1.5 text-reguard-dark-gray shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6' ${
                  emailHasError &&
                  'text-reguard-red ring-red-300 placeholder:text-reguard-red focus:ring-red-500'
                }`}
                value={email}
                onChange={event => setEmail(event.currentTarget.value)}
              />
              {emailHasError && (
                <div className="pointer-events-none flex items-center pr-3 float-right relative bottom-7">
                  <svg
                    className="h-5 w-5 text-reguard-red"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      fillRule="evenodd"
                      d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-5a.75.75 0 01.75.75v4.5a.75.75 0 01-1.5 0v-4.5A.75.75 0 0110 5zm0 10a1 1 0 100-2 1 1 0 000 2z"
                      clipRule="evenodd"
                    />
                  </svg>
                </div>
              )}
            </div>

            {emailHasError && (
              <p className="mt-2 text-sm text-reguard-red" id="email-error">
                {t('not_valid_email_address')}
              </p>
            )}
          </div>

          <div>
            <div className="flex items-center justify-between">
              <label
                htmlFor="password"
                className="block text-sm font-medium leading-6 text-reguard-dark-gray"
              >
                {t('password')}
              </label>
              <div className="text-sm">
                <a
                  href="/"
                  className="font-semibold text-reguard-gray hover:text-reguard-green"
                  onClick={event => handleResetPassword(event)}
                >
                  {t('forgot_pass')}
                </a>
              </div>
            </div>
            <div className="mt-2">
              <input
                id="password"
                name="password"
                type="password"
                autoComplete="current-password"
                required
                value={password}
                onChange={event => setPassword(event.currentTarget.value)}
                className={`block w-full rounded-md border-0 py-1.5 text-reguard-dark-gray shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6'
                } ${
                  passwordHasError &&
                  'text-reguard-red ring-red-300 placeholder:text-reguard-red focus:ring-red-500'
                }`}
              />
              {passwordHasError && (
                <div className="pointer-events-none flex items-center pr-3 float-right relative bottom-7">
                  <svg
                    className="h-5 w-5 text-reguard-red"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      fillRule="evenodd"
                      d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-5a.75.75 0 01.75.75v4.5a.75.75 0 01-1.5 0v-4.5A.75.75 0 0110 5zm0 10a1 1 0 100-2 1 1 0 000 2z"
                      clipRule="evenodd"
                    />
                  </svg>
                </div>
              )}
            </div>

            {passwordHasError && (
              <p className="mt-2 text-sm text-reguard-red" id="password-error">
                {t('not_valid_password')}
              </p>
            )}

            {error && error.length > 0 && (
              <p
                className="mt-2 text-sm text-reguard-red"
                id="submission-error"
              >
                {error}
              </p>
            )}
          </div>

          <div>
            <button
              type="submit"
              onClick={event => handleLoginSubmission(event)}
              className={`${'flex w-full justify-center rounded-md bg-reguard-dark-gray px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-reguard-green focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-emerald-400'}
                                    ${isSubmitting && 'disabled'}`}
            >
              {t('sign_in')}
              {isSubmitting && <LoadingSpinner />}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};
