import {
  FilterChangeEvent,
  FilterConfig,
  FilterOption,
} from '../../../../helpers/types';

export function setupOnChange(
  config: FilterConfig,
  setOption: (id: string) => void,
) {
  const options = config.options || [];

  const firstOptionId = options[0].id || '';

  return async (event: FilterChangeEvent) => {
    if (!event) {
      return setOption(firstOptionId);
    }

    const optionId = event.target.value;
    const selectedOption = options.find((option: FilterOption) => {
      return option.id === optionId;
    });

    setOption(selectedOption?.id || '');
  };
}
