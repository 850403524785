import React from 'react';
import { useLocation } from 'react-router-dom';
import { MenuNavCategory, NavCategoryType } from '@cortex/shared';

import { MenuSubCategory } from './menu-sub-category';

import '../navbar.css';

interface MenuProps {
  categories: NavCategoryType[];
  popoverOpen: boolean;
  setPopoverOpen: (popoverOpen: boolean) => void;
  selectedMenuCategory: string;
  setSelectedMenuCategory: (selectedMenuCategory: string) => void;
}

export const MenuCategory = ({
  categories,
  popoverOpen,
  setPopoverOpen,
  selectedMenuCategory,
  setSelectedMenuCategory,
}: MenuProps) => {
  const location = useLocation();
  const { pathname } = location;
  const splitLocation = pathname.split('/');
  const handleSelected = (
    event: React.MouseEvent<HTMLElement> | React.FocusEvent<HTMLElement>,
    category: NavCategoryType,
  ) => {
    if (category.children.length === 0) {
      event.preventDefault();
      return (window.location.href = category.path);
    }

    setSelectedMenuCategory(category.name);
    setPopoverOpen(true);
  };

  return (
    <div className="flex gap-2">
      {categories.map(category => (
        <React.Fragment key={category.name}>
          <MenuNavCategory
            category={category}
            popoverOpen={popoverOpen}
            setPopoverOpen={setPopoverOpen}
            selectedMenuCategory={selectedMenuCategory}
            setSelectedMenuCategory={setSelectedMenuCategory}
            handleSelected={handleSelected}
            isActive={splitLocation[1] === category.name}
          >
            {category.children.length > 0 && (
              <MenuSubCategory
                category={category.name}
                subcategoryData={category.children}
                popoverOpen={popoverOpen}
                setPopoverOpen={setPopoverOpen}
                selectedMenuCategory={selectedMenuCategory}
              />
            )}
          </MenuNavCategory>
        </React.Fragment>
      ))}
    </div>
  );
};
